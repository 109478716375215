import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import {
    apiForgotPassword,
    apiResetPassword,
    apiSignIn,
    apiSignOut,
    apiUserDetails,
} from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { toast } from 'components/ui'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { accessToken, signedIn } = useSelector((state) => state.auth.session)

    const signIn = (values, setSubmitting, setErrors) => {
        apiSignIn(values)
            .then(({ data, headers }) => {
                if (!data?.data?.is_admin) {
                    toast.error('Invalid login credentials. Please try again.')
                    return setSubmitting(false)
                }
                dispatch(
                    onSignInSuccess({
                        uid: headers.get('uid'),
                        tokenType: headers.get('token-type'),
                        accessToken: headers.get('access-token'),
                        expiry: headers.get('expiry'),
                        client: headers.get('client'),
                    }),
                )
                dispatch(
                    setUser(
                        data.data || {
                            authority: ['USER'],
                        },
                    ),
                )
                const redirectUrl = query.get('back-to')
                navigate(redirectUrl ?? '/pallet-matching/3pl-enquiries')
            })
            .catch((data) => {
                setSubmitting(false)
                const errors = data?.response?.data?.errors
                setErrors(errors)
            })
    }

    const getUserDetails = () => {
        apiUserDetails()
            .then(({ data }) => {
                dispatch(setUser(data || { authority: ['USER'] }))
            })
            .catch((e) => console.log(e))
    }

    const signOut = () => {
        apiSignOut().then(handleSignOut).catch(handleSignOut)
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate('/sign-in')
    }

    const forgotPassword = (values, setSubmitting, setEmailSent) => {
        apiForgotPassword(values)
            .then(() => setEmailSent(true))
            .catch(() => setEmailSent(false))
            .finally(() => setSubmitting(false))
    }

    const resetPassword = (values, setSubmitting, setResetComplete) => {
        values.reset_password_token = query.get('token')
        apiResetPassword({ user: values })
            .then(() => setResetComplete(true))
            .catch(() => setResetComplete(false))
            .finally(() => setSubmitting(false))
    }

    return {
        authenticated: accessToken && signedIn,
        signIn,
        getUserDetails,
        signOut,
        forgotPassword,
        resetPassword,
    }
}

export default useAuth
